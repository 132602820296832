import React from "react"
import tw, { styled } from "twin.macro"

import Seo from "../components/Seo"
import Layout from "../components/Layout"
import ContentContainer from "../components/ContentContainer"
import TextContainer from "../components/TextContainer"
import Team from "../components/Team"
import PromoData from "../components/PromoData"

const HeaderSpan = styled.span`
  ${tw`text-2xl`}
`

const AboutUs = () => {
  return (
    <Layout>
      <Seo
        title="Posterus Wrocław - kursy księgowości, rachunkowości i kadrowo-płacowe"
        description="Posterus - szkolenia księgowości i kadrowo-płacowe we Wrocławiu. Lokalizacja w centrum - blisko dworca PKP"
      />
      <ContentContainer>
        <TextContainer>
          <h1>
            Posterus Wrocław <br />
            <HeaderSpan> kursy księgowości i rachunkowości</HeaderSpan>
          </h1>
          <p>
            Firma „Posterus” Bożena Krasnodębska została wpisana do rejestru
            ewidencji działalności gospodarczej pod numerem 210004, głównym
            rodzajem prowadzonej działalności są pozaszkolne formy edukacji.
            Przeprowadziliśmy setki kursów i szkoleń zawodowych z zakresu
            księgowości.
          </p>

          <p>
            Swoją ofertę kierujemy zarówno do osób które nie mają wiedzy ani
            praktyki w dziedzinie księgowości, a chciałyby pracować w zawodzie
            księgowego jak również do osób pragnących podnieść swoje
            dotychczasowe kwalifikacje.
          </p>

          <p>
            Zajęcia prowadzimy indywidualnie. Zakres oraz miejsce zajęć
            dostosowujemy do potrzeb i możliwości uczestników.
          </p>

          <p>
            Ze względu na wartość merytoryczną, dużą ilość praktyki oraz
            atrakcyjne ceny organizowane przez nas szkolenia cieszą się dużym
            zainteresowaniem ze strony klientów indywidualnych, firm oraz
            instytucji. Na bieżąco prowadzimy monitoring prowadzonych zajęć , po
            zakończeniu kusu uczestnicy wyrażają w przeprowadzanych przez nas
            ankietach opinie o odbytym kusie.
          </p>

          <p>
            Kursy organizowane są w oparciu o stale aktualizowane, modułowe
            programy szkolenia, dostosowane do zmieniających się przepisów
            prawnych oraz wymagań pracodawców. Wiele firm skorzystało z naszych usług, m.in <a href="https://panelepremium.pl/">Panele Premium - sklep z podłogami.</a>
          </p>

          <p>
            Starannie dobieramy współpracowników. Zatrudniamy wysoko
            wykwalifikową kadrę z wieloletnim doświadczeniem zawodowym
            posiadającą umiejętność łatwego i skutecznego przekazywania wiedzy
            innym osobom.
          </p>

          <p>
            Posiadamy wpis do Rejestru Instytucji Szkoleniowych nr
            2.02/00126/2013
          </p>
        </TextContainer>
      </ContentContainer>
      <PromoData />
      <Team />
    </Layout>
  )
}

export default AboutUs
